@keyframes antSlideUp {
  0% {
    transform: translateY(100%);
    transform-origin: 0 0;
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    transform-origin: 0 0;
    opacity: 1;
  }
}

@keyframes antSlideOut {
  0% {
    transform: translateY(0);
    transform-origin: 0 0;
    opacity: 1;
  }

  100% {
    transform: translateY(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}

.ant-modal-slide-up-enter,
.ant-modal-slide-up-appear {
  transform: scale(0);
  transform-origin: 0 0;
  opacity: 0;
  animation-play-state: paused;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.ant-modal-slide-up-leave {
  animation-play-state: paused;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.ant-modal-slide-up-enter.ant-modal-slide-up-enter-active,
.ant-modal-slide-up-appear.ant-modal-slide-up-appear-active {
  animation-name: antSlideUp;
  animation-play-state: running;
}

.ant-modal-slide-up-leave.ant-modal-slide-up-leave-active {
  animation-name: antSlideOut;
  animation-play-state: running;
  pointer-events: none;
}
